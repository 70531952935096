@import './../../../../../../styles/_variables'

.DownloadASRSPrintVersion
  margin-bottom: 1.78rem

  .line-with-text
    display: flex
    flex-direction: row
    font-size: 0.85rem
    margin-bottom: 1.5rem

    &:before,
    &:after
      content: ""
      flex: 1 1
      border-bottom: 1px solid rgba($white, 0.5)
      margin: auto

    &:before
      margin-right: 1em

    &:after
      margin-left: 1em

  .btn-default
    border-radius: 2rem
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    padding: 1rem 1.4rem
    > span
      width: 50%
      text-align: left

  @media (min-width: map_get($grid-breakpoints, 'sm'))
    margin-bottom: 4rem

    .line-with-text
      font-size: 0.7rem
      margin-bottom: 1rem

    .btn-default
      border-radius: 0.5rem
      width: auto
      display: inline-block
      padding: 0.65rem 1rem

      > span
        width: initial
        text-align: initial

