@import './../../../../../styles/_variables'
@import './../Question/Choices/Choice/ChoiceBackgrounds'

.Summary

  .Main
    padding-top: 2rem

  .summary-item
    border-bottom: 1px solid rgba($white, 0.5)
    font-size: 0.9rem
    cursor: pointer
    display: flex
    flex-direction: row

    &:hover
      background: rgba($white, 0.1)

    .number
      margin-right: 0.5rem
      font-weight: 700
      font-size: 1.4rem

    .question-and-answer
      display: flex
      flex-direction: column
      flex-grow: 1

      @media (min-width: map_get($grid-breakpoints, 'sm'))
        flex-direction: row
        justify-content: space-between

        .question
          flex-basis: 75%

        .answer-wrapper
          flex-basis: 25%

          .answer
            padding: 0.3rem 1.6rem
            margin-top: 0.3rem
            margin-bottom: 0.3rem
            width: auto

      .question
        margin-top: 0.3rem
        color: rgba($white, 0.8)

      .answer-wrapper
        width: 100%

        .answer
          display: flex
          justify-content: center
          align-items: center
          color: $purple
          line-height: 1.15
          padding: 1rem
          border-radius: 2rem
          text-align: center
          width: 100%
          margin-top: 0.5rem
          margin-bottom: 0.6rem

  .conditions-met
    font-size: 1.5rem
    font-weight: bold
    text-transform: uppercase
    flex-basis: 100%
    margin-bottom: 2rem

    @media (min-width: map_get($grid-breakpoints, 'sm'))
      margin-bottom: 0
      flex-basis: auto

    img
      width: 2.4rem
      margin-right: 1rem
