@import './../../../../styles/_variables'

.Footer
  background: white

  > .col
    padding-left: 15px
    padding-right: 15px

  footer
    margin-top: 0.75rem
    margin-bottom: 0.75rem
    display: flex
    justify-content: space-between
    align-items: center
    color: rgba($black, 0.6)
    font-size: 0.9rem

    .version
      color: rgba($black, 0.3)
      font-size: 0.8rem

    @media (min-width: map_get($grid-breakpoints, 'sm'))
      font-size: 1rem
      margin-top: 1rem
      margin-bottom: 1rem
