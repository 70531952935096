@import './../../../styles/_variables'
@import './../../../styles/mixins'

.Home
  .EnterButton
    @include box-shadow(none)
    text-transform: uppercase
    font-size: 1.28rem
    padding: 3rem 4rem
    font-weight: bold
    border-radius: 3rem

    @media (min-width: map_get($grid-breakpoints, 'sm'))
      font-size: 1.6em




