@import './../../../../../../../styles/_variables'
@import 'ChoiceBackgrounds'

.Choice
  position: relative

  .choice-keyboard-binding
    $size: 2.4rem
    position: absolute
    left: 0
    right: 0
    bottom: -$size/2
    width: $size
    height: $size
    border-radius: $size/2
    color: $white
    border: 1px solid $white
    background: $purple
    margin: 0 auto
    display: flex
    justify-content: center
    align-items: center
    transition: background 0.2s

  .active
    .choice-keyboard-binding
      background: $green

  .btn.btn-choice
    color: $purple
    border: 3px solid
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    font-size: 1.28rem
    padding: 1rem
    border-radius: 2rem
    box-shadow: none
    -webkit-box-shadow: none
    -moz-box-shadow: none
    width: 100%
    height: 100%
    transition: border-color 0.2s

    .choice-icon
      display: none

    @media (min-width: map_get($grid-breakpoints, 'sm'))
      font-size: 1.1rem
      padding-bottom: 1.4rem

      .choice-icon
        width: 3rem
        height: 3rem
        display: flex
        justify-content: center
        align-items: center
        margin-bottom: 0.5rem

    &.active
      border-color: $green