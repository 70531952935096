@import './../../../../styles/_variables'

.Notes
  position: relative

  textarea
    width: 100%
    height: 2.75rem
    transition: height 0.25s ease-in-out
    background: transparent
    color: rgba($white, 0.8)
    border: 1px solid rgba($white, 0.8)
    resize: none
    border-radius: 0.5rem
    padding: 0.5rem 1rem

    &.active
      height: 10rem
      color: $white
      border-color: $white

    &::placeholder
      color: rgba($white, 0.6)

    &:focus
      outline: none !important

  .notes-plus
    position: absolute
    right: 0.65rem
    top: 0.5rem
    opacity: 0
    transition: opacity 0.25s ease-in-out
    pointer-events: none

    @media (min-width: map_get($grid-breakpoints, 'sm'))
      top: 0.65rem

    &.active
      opacity: 1

  .notes-help
    display: flex
    letter-spacing: 0.25px
    opacity: 0
    transition: opacity 0.25s ease-in-out

    &.active
      opacity: 1

    img
      margin: 0 0.25rem

      &.enter-icon
        height: 0.65rem
        margin: 0 0.35rem
        align-self: flex-end

    .btn.btn-choice
      width: 15%

  .choice
    position: relative

    .choice-keyboard-binding
      $size: 2.4rem
      position: absolute
      left: 0
      right: 0
      bottom: -$size/2
      width: $size
      height: $size
      border-radius: $size/2
      color: $white
      border: 1px solid $white
      background: $purple
      margin: 0 auto
      display: flex
      justify-content: center
      align-items: center

    &.active
      .choice-keyboard-binding
        background: $green

    &.btn.btn-choice
      background: $white
      color: $purple
      border: 3px solid $white
      display: flex
      justify-content: center
      align-items: center
      font-size: 1.1rem
      padding: 1rem 1rem 1.4rem
      border-radius: 2rem
      box-shadow: none
      -webkit-box-shadow: none
      -moz-box-shadow: none

      &:hover
        background: darken($white, 10%)

      &.active
        border-color: $green
