.modal
  .modal-content
    border-radius: 2rem
    padding: 0.5rem 0.5rem

  .modal-header
    color: $grey
    border: none

    .modal-title
      font-weight: bold
      font-size: 1rem

  .modal-body
    color: $grey
    font-size: 1rem

  .modal-footer
    padding: 1rem 0
    margin: 0 1rem

    .btn
      font-size: 0.8rem
      font-weight: bold
      padding: 0.5rem 1rem
      @include box-shadow(none)

      &.btn-danger
        background: transparent
        border: 2px solid $danger
        color: $danger

        &:hover
          background: rgba($grey, 0.05)

      &.btn-default
        background: transparent
        color: $grey

        &:hover
          background: rgba($grey, 0.05)