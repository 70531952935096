@import './../../../../../../styles/_variables'

.Choices
  margin: 1rem 0
  display: flex
  justify-content: center
  flex-direction: column

  .Choice
    width: 100%
    margin-bottom: 1.42rem


@media (min-width: map_get($grid-breakpoints, 'sm'))
  .Choices
    margin: 2rem 0 3rem
    flex-direction: row

    &.question-with-2-choices
      .Choice
        margin-left: 2rem
        margin-right: 2rem
        width: 25%

    &.question-with-3-choices
      .Choice
        margin-left: 1.4rem
        margin-right: 1.4rem
        width: 25%

    &.question-with-4-choices
      .Choice
        margin-left: 0.8rem
        margin-right: 0.8rem
        width: 20%

    &.question-with-5-choices
      .Choice
        margin-left: 0.6rem
        margin-right: 0.6rem
        width: 15%
