@import ./_variables
@import ./../../node_modules/bootstrap/scss/bootstrap

@import ./mixins
@import ./fonts

@import ./alert
@import ./button
@import ./modal
@import ./toast

html,
body
  font-size: 14px

  @media (min-width: map_get($grid-breakpoints, 'sm'))
    font-size: 16px

  .text-small
    font-size: 0.7rem

  hr
    border-color: rgba($white, 0.5)
    width: 100%

  h1
    text-align: center
    font-size: 1.85rem
    line-height: 1.25
    font-weight: normal

    @media (min-width: map_get($grid-breakpoints, 'sm'))
      font-size: 2rem
      line-height: 1.5
      font-weight: bold

