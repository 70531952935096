$purple: #67296D
$green: #91BE35
$grey: #777777
$black: #212529
$white: #ffffff

$primary: $purple
$secondary: $green
$danger: #a22424

$body-color: $white
$body-bg: $black

$font-family-sans-serif: 'Montserrat', sans-serif

@import "~bootstrap/scss/functions"
@import "~bootstrap/scss/variables"