@import './../../../../../../styles/_variables'

.QuestionText
  margin-top: 3rem
  text-align: center

  .question
    font-size: 1.4rem

  @media (min-width: map_get($grid-breakpoints, 'sm'))
    $width: 84%
    margin: 3rem (100%-$width)/2 2rem

    .question
      font-size: 1.85rem
      line-height: 1.8
      margin-bottom: 1rem
