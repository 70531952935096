@import './../../../../styles/_variables'
@import './../../../../styles/mixins'

.PersonalData
  .form-group
    .form-label
      margin-bottom: 0

    .form-control
      background: transparent
      border-radius: 0
      border: none
      border-bottom: 1px solid rgba($white, 0.5)
      font-size: 1.42rem
      padding: 0
      caret-color: $white
      color: white

      &:focus
        @include box-shadow(none)

    @media (min-width: map_get($grid-breakpoints, 'sm'))
      .form-label
        margin-bottom: 0.5rem

      .form-control
        font-size: 2rem
