@import './../../../../../../../styles/_variables'

.Choice,
.Summary
  $color: $white

  .choice-1-of-2
    background: $color
    border-color: $color

    &:hover
      background: darken($color, 10%)

  .choice-2-of-2
    background: $color
    border-color: $color

    &:hover
      background: darken($color, 10%)

  .choice-1-of-3
    background: $color
    border-color: $color

    &:hover
      background: darken($color, 10%)

  .choice-2-of-3
    background: $color
    border-color: $color

    &:hover
      background: darken($color, 10%)

  .choice-3-of-3
    background: $color
    border-color: $color

    &:hover
      background: darken($color, 10%)

  .choice-1-of-4
    background: $color
    border-color: $color

    &:hover
      background: darken($color, 10%)

  .choice-2-of-4
    background: $color
    border-color: $color

    &:hover
      background: darken($color, 10%)

  .choice-3-of-4
    background: $color
    border-color: $color

    &:hover
      background: darken($color, 10%)

  .choice-4-of-4
    background: $color
    border-color: $color

    &:hover
      background: darken($color, 10%)

  .choice-1-of-5
    background: $color
    border-color: $color

    &:hover
      background: darken($color, 10%)

  .choice-2-of-5
    background: $color
    border-color: $color

    &:hover
      background: darken($color, 10%)

  .choice-3-of-5
    background: $color
    border-color: $color

    &:hover
      background: darken($color, 10%)

  .choice-4-of-5
    background: $color
    border-color: $color

    &:hover
      background: darken($color, 10%)

  .choice-5-of-5
    background: $color
    border-color: $color

    &:hover
      background: darken($color, 10%)
