@import './../../../../../../styles/_variables'

.AdditionalQuestions
  .accordion

    .additional-questions-toggle
      background: transparent
      border: none
      color: $white
      text-transform: uppercase
      font-weight: bold
      font-size: 1.2rem

      &:focus
        outline: none !important
        border: none

      img
        transform: scaleY(1)
        transition: transform 0.2s ease-in-out

      &.active
        img
          transform: scaleY(-1)

  .form-check
    display: block
    position: relative
    padding-left: 30px
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    font-size: 1.125rem

    input
      position: absolute
      opacity: 0
      cursor: pointer
      height: 0
      width: 0

    .checkmark
      position: absolute
      top: 0
      left: 0
      height: 15px
      width: 15px
      background-color: rgba($white, 0.2)
      border: 1px solid $white
      border-radius: 3px
      margin-top: 0.3rem

      &:after
        content: ""
        position: absolute
        display: none
        left: 4px
        top: 1px
        width: 5px
        height: 9px
        border: solid white
        border-width: 0 1px 1px 0
        -webkit-transform: rotate(45deg)
        -ms-transform: rotate(45deg)
        transform: rotate(45deg)

    input:checked ~ label .checkmark:after
      display: block



  @media (min-width: map_get($grid-breakpoints, 'sm'))
    .Info
      margin-bottom: 1rem