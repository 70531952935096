@font-face
  font-family: "Montserrat"
  src: url('./../fonts/Montserrat-Regular.ttf')
  font-weight: normal

@font-face
  font-family: "Montserrat"
  src: url('./../fonts/Montserrat-SemiBold.ttf')
  font-weight: bold

@font-face
  font-family: "Montserrat"
  src: url('./../fonts/Montserrat-Italic.ttf')
  font-weight: normal
  font-style: italic

@font-face
  font-family: "Montserrat"
  src: url('./../fonts/Montserrat-SemiBoldItalic.ttf')
  font-weight: bold
  font-style: italic
