@import './../../../../../../styles/_variables'

.Info
  margin-top: 0
  margin-bottom: 1rem

  @media (min-width: map_get($grid-breakpoints, 'sm'))
    margin-bottom: 2rem

  &:not(.show)
    margin-top: 0
    margin-bottom: 0
    .alert
      padding: 0
      margin: 0
      border: 0